import React from 'react'
import { makeStyles } from '@material-ui/core'

export const Lines = ({ height }) => {
  const classes = useStyles();
  return (
    <div className={classes.lines}>
      <div className={classes.lineGreen} style={{height:height}}></div>
      <div className={classes.lineBlue} style={{ height: height }}></div>
      {/* <div className={classes.lineRed} style={{ height: height }}></div> */}
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  lines: {
    display: 'flex',
    width: '100%',
  },
  lineGreen: {
    backgroundColor: '#1BA538',
    width: '50%',
  },
  lineBlue: {
    backgroundColor: '#483E8F',
    width: '50%',
  },
  lineRed: {
    backgroundColor: '#F30000',
    width: '100%',
  },
}));
