import React from 'react';
import './Banner.css';
import LogoUTN from '../../assets/images/LOGOFCPCUTN.png';
import { Container, makeStyles, Typography } from '@material-ui/core';

export const Banner = () => {
  const classes = useStyles();

  return (
    <div>
      <Container className={classes.root}>
        {/* TODO: cambiar el logo fcpc utn */}
        <img src={LogoUTN} className="img-logos" alt="logo" />
        <center>
          <Typography color="initial" className={classes.title}>
          FONDO COMPLEMENTARIO PREVISIONAL CERRADO DE CESANTÍA INDEXADA DE LA UNIVERSIDAD TÉCNICA DEL NORTE
          </Typography>
          {/* <span className="titulo">FCPC DE JUBILACION PRIVADA DE LOS SERVIDORES DE CARRERA DEL GPI</span> */}
        </center>
        {/* <img src={LogoBiess} className="img-logos2" alt="logo" /> */}
      </Container>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    padding: '2em',
  },
  title: {
    fontSize: '2rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('md')]: {
      fontSize: 30,
      // padding: '0px 100px',
    },
  },
}));
