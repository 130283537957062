import React from 'react';
import SuperIntendencia from '../../assets/images/super-intendencia-bancos.webp';
import UAFE from '../../assets/images/UAFE.webp';
import SRI from '../../assets/images/SRI.webp';
import { Typography } from '@material-ui/core';

export const Enlaces = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', marginTop: 30, marginBottom: 30 }}>
      <Typography variant="h4" color="initial" style={{ marginBottom: 30 }}>
        ENLACES DE INTERÉS
      </Typography>
      <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexWrap: 'wrap' }}>
        <a href="https://www.superbancos.gob.ec/bancos/" target="_blank" rel="noreferrer">
          <div style={{ width: 300, margin: 10, marginBottom: 30, display: 'flex', justifyContent: 'center' }}>
            <img src={SuperIntendencia} alt="Super intendencia de bancos" width="200" />
          </div>
        </a>
        <a href="https://www.sri.gob.ec/web/intersri/home" target="_blank" rel="noreferrer">
          <div style={{ width: 300, margin: 10, marginBottom: 30, display: 'flex', justifyContent: 'center' }}>
            <img src={SRI} alt="SRI" width="150" />
          </div>
        </a>
        <a href="https://www.uafe.gob.ec/" target="_blank" rel="noreferrer">
          <div style={{ width: 300, margin: 10, marginBottom: 30, display: 'flex', justifyContent: 'center' }}>
            <img src={UAFE} alt="UAFE" width="200" />
          </div>
        </a>
      </div>
    </div>
  );
};
