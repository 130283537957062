import React from 'react';
import { Menu } from 'antd';
import { Link } from 'react-router-dom';
// import { Link as LinkScroll } from 'react-scroll';

import './MenuBar.css';

export const MenuBar = ({ paginaActive, setPaginaActive }) => {
  const subMenuCodigo = [
    // { icon: '', path: '/codigoEtica', name: 'Código y Buen Gobierno' },
    {
      icon: '',
      path: '/codigoEtica',
      name: 'Generalidades y Definiciones',
      more: [
        { icon: '', path: '/introduccion', name: 'Introducción' },
        { icon: '', path: '/conceptosJustificación', name: 'Conceptos y Justificación' },
        { icon: '', path: '/disposionesPreliminares', name: 'Disposiciones Preliminares' },
        { icon: '', path: '/normas', name: 'Normas' },
        { icon: '', path: '/prohibicion', name: 'Prohibición' },
        { icon: '', path: '/conflictos', name: 'Conflictos' },
        { icon: '', path: '/actividadesPoliticas', name: 'Actividades Políticas' },
        { icon: '', path: '/medioAmbiente', name: 'Medio Ambiente' },
      ],
    },
    {
      icon: '',
      path: '/codigoEtica',
      name: 'Documento',
      more: [
        { icon: '', path: '/comiteEtica', name: 'Comite de Ética' },
        { icon: '', path: '/cumplimiento', name: 'Cumplimiento' },
        { icon: '', path: '/medidasDisciplinarias', name: 'Medidas Disciplinarias' },
        { icon: '', path: '/accesoPoliticas', name: 'Acceso a las Políticas' },
      ],
    },
  ];

  const handleClick = (e) => {
    console.log('click ', e);
    setPaginaActive(e.key);
  };

  return (
    <Menu onClick={handleClick} theme="light" mode="horizontal" defaultSelectedKeys={[paginaActive]} className="menu">
      <Menu.Item key="1">
        <Link to="/">Inicio</Link>
      </Menu.Item>

      <Menu.SubMenu key="Submenu1" title="La Institución">
        <Menu.Item key="insti:1">
          <Link to="/institucion">Filosofía Institucional</Link>
        </Menu.Item>
        <Menu.Item key="insti:2">
          <Link to="/planificacionEstrategica">Planificación Estratégica</Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.SubMenu key="Submenu2" title="Código de Ética y Gobierno Corporativo">
        {subMenuCodigo.map((item, idx) => (
          <Menu.SubMenu key={`sub:${idx}`} title={item.name}>
            {item.more &&
              item.more.map((opt, i) => (
                <Menu.Item key={opt.path}>
                  <Link to={opt.path}>{opt.name}</Link>
                </Menu.Item>
              ))}
          </Menu.SubMenu>
        ))}
        <Menu.Item key="cod:2123">
          <Link to="/anexo1">Anexo 1 - indicadores de gobierno corporativo</Link>
        </Menu.Item>
      </Menu.SubMenu>

      <Menu.Item key="4">
        <Link to="/educacionFinanciera">Educación Financiera</Link>
      </Menu.Item>
      <Menu.Item key="5">
        {/* {paginaActive > 0 ? ( */}
        <Link to="/personal">Nuestro Personal</Link>
      </Menu.Item>
      <Menu.Item key="6">
        <Link to="/contacto">Contacto</Link>
      </Menu.Item>
      <Menu.Item key="7">
        <Link to="/gestionperiodo2015-2021">Informes</Link>
      </Menu.Item>
      <Menu.SubMenu key="SubmenuMas" title="Normativa">
        <Menu.Item key="Mas:1">
          <Link to="/reglamento-de-inversiones-privativas">Reglamento de inversiones privativas.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:2">
          <Link to="/manual-archivo-general">Manual de archivo general y gestion documental.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:3">
          <Link to="/manual-de-normas-y-procedimientos-viaticos-y-dietas">Manual de normas y procedimientos viaticos y dietas.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:4">
          <Link to="/reglamento-de-anticipos-de-sueldos-y-honorarios">Reglamento de anticipos de sueldos y honorarios.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:5">
          <Link to="/ley-orgánica-de-proteccion-de-datos-personales">Reglamento para el tratamiento de datos personales.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:6">
          <Link to="/quejas-y-sugerencias">Instructivo de atencion al aprticipe consultas, quejas y reclamos.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:7">
          <Link to="/manual-de-prevencion-de-lavado-de-activos">Manual de prevención de lavado de activos.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:8">
          <Link to="/manual-de-riesgos-operativos">Manual de riesgos operativos.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:9">
          <Link to="/manual-organizacional-y-de-funciones">Manual organizacional y de funciones.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:10">
          <Link to="/manual-de-politicas-y-procedimientos-contables">Manual de políticas y procedimientos contables.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:11">
          <Link to="/instructivo-de-gastos-caja-chica">Instructivo de gastos caja chica.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:12">
          <Link to="/codigo-de-etica-y-buen-gobierno-corporativo">Código de ética y buen gobierno corporativo.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:13">
          <Link to="/manual-de-inversiones-no-privativas">Manual de inversiones no privativas.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:14">
          <Link to="/manual-de-prestaciones-y-desafiliaciones">Manual de prestaciones y desafiliaciones.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:15">
          <Link to="/plan-de-continuidad-del-negocio">Plan de continuidad del negocio.</Link>
        </Menu.Item>
        <Menu.Item key="Mas:16">
          <Link to="/bases-y-reglamento-del-concurso-de-meritos">Bases y reglamento del concurso de méritos y oposición para el cargo de gerente del fcpc utn.</Link>
        </Menu.Item>
      </Menu.SubMenu>
    </Menu>
  );
};
