import React, { useState, useEffect } from 'react';
import Typography from '@material-ui/core/Typography';
import { Container, Grid, makeStyles, Backdrop, CircularProgress, Grow } from '@material-ui/core';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

import 'mapbox-gl/dist/mapbox-gl.css';

import { Lines } from '../components/Lines';
import { Helmet } from 'react-helmet';
import { Map } from '../components/Map';
import { FormContact } from '../components/FormContact';

export const Contact = ({ setPaginaActive }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  // 0.3589614610537069, -78.11167966136317

  useEffect(() => {
    setPaginaActive(1);
  }, [setPaginaActive]);

  return (
    <>
      <Helmet>
        <title>Contacto | fcpc-UTN</title>
      </Helmet>
      <Container maxWidth="md" id="contacto">
        <Backdrop className={classes.backdrop} open={open}>
          <CircularProgress color="primary" />
        </Backdrop>
        <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 800 } : {})}>
          <Typography variant="h3" color="initial" align="center" className={classes.marginx4}>
            CONTACTO
          </Typography>
        </Grow>
        <Lines height={3} />
        <div className={classes.marginTopBottomx4}>
          <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 800 } : {})}>
            <Typography variant="subtitle2" color="textPrimary">
              Ponemos a tu disposición varias formas de contactarnos:
            </Typography>
          </Grow>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} style={{ width: '100%' }}>
              <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 800 } : {})}>
                <div className={classes.displayCenterColumn}>
                  <Typography variant="subtitle1" color="initial" className={classes.typographyWithIcon}>
                    <LocationOnRoundedIcon color="primary" className={classes.mrIcon} /> Ubicación
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.value}>
                    Ciudadela Universitaria Barrio “El Olivo”
                  </Typography>
                </div>
              </Grow>
              <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 800 } : {})}>
                <div className={classes.displayCenterColumn}>
                  <Typography variant="subtitle1" color="initial" className={classes.typographyWithIcon}>
                    <PhoneRoundedIcon color="primary" className={classes.mrIcon} /> Teléfono
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.value}>
                    2997800, Ext. 7280
                  </Typography>
                </div>
              </Grow>
              <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 800 } : {})}>
                <div className={classes.displayCenterColumn}>
                  <Typography variant="subtitle1" color="initial" className={classes.typographyWithIcon}>
                    <EmailRoundedIcon color="primary" className={classes.mrIcon} /> Correo electrónico
                  </Typography>
                  <Typography variant="subtitle1" color="textSecondary" className={classes.value}>
                    fojiutn@hotmail.com
                  </Typography>
                </div>
              </Grow>
            </Grid>
            <Grid item xs={12} sm={6} style={{ width: '100%' }}>
              <FormContact setOpen={setOpen} />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Map />
            </Grid>
          </Grid>
        </div>
      </Container>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  marginx4: {
    margin: theme.spacing(4),
  },
  marginTopBottomx4: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  displayCenterColumn: {
    diplay: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  containerForm: {
    diplay: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  containerInput: {
    diplay: 'flex',
    alignItems: 'center',
  },
  value: {
    marginLeft: theme.spacing(4.5),
  },
  typographyWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  mrIcon: {
    marginRight: theme.spacing(1.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
}));
