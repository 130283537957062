import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
import emailjs from 'emailjs-com';
import { Grid, makeStyles, TextField, Button, Grow } from '@material-ui/core';
import PersonRoundedIcon from '@material-ui/icons/PersonRounded';
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

const initForm = {
  nombre: '',
  email: '',
  mensaje: '',
};

export const FormContact = ({ setOpen }) => {
  const classes = useStyles();
  const [form, setForm] = useState(initForm);
  const { enqueueSnackbar } = useSnackbar();

  const onChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line
    const emailValid = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (form.nombre === '') {
      enqueueSnackbar('Debe llenar el campo nombre', { variant: 'error', autoHideDuration: 1000 });
    } else if (form.email === '') {
      enqueueSnackbar('Debe llenar el campo correo electrónico', { variant: 'error', autoHideDuration: 1000 });
    } else if (!emailValid.test(form.email)) {
      enqueueSnackbar('Debe enviar un correo válido', { variant: 'error', autoHideDuration: 1000 });
    } else if (form.mensaje === '') {
      enqueueSnackbar('Debe llenar el campo mensaje', { variant: 'error', autoHideDuration: 1000 });
    } else {
      setOpen(true);

      emailjs.send('service_1gbi4r1', 'template_rsl20fc', form, 'Irno5ck1FdT2Aoalg').then(
        (response) => {
          console.log('SUCCESS!', response.status, response.text);
          setForm(initForm);
          setOpen(false);
          enqueueSnackbar('Mensaje enviado', { variant: 'success', autoHideDuration: 2000 });
        },
        (err) => {
          console.log('FAILED...', err);
          setOpen(false);
          enqueueSnackbar('Ha ocurrido error intentelo mas tarde', { variant: 'error,  autoHideDuration: 2000' });
        }
      );
    }
  };

  return (
    <>
      <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 800 } : {})}>
        <div className={classes.containerForm}>
          <Grid container spacing={1} alignItems="flex-end" className={classes.containerInput}>
            <Grid item xs={1}>
              <PersonRoundedIcon color="primary" />
            </Grid>
            <Grid item xs={10}>
              <TextField color="primary" className={classes.margin} name="nombre" label="Nombre" variant="outlined" value={form.nombre} onChange={onChange} fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end" className={classes.containerInput}>
            <Grid item xs={1}>
              <EmailRoundedIcon color="primary" />
            </Grid>
            <Grid item xs={10}>
              <TextField color="primary" className={classes.margin} name="email" label="Correo Electrónico" variant="outlined" value={form.email} onChange={onChange} fullWidth />
            </Grid>
          </Grid>
          <Grid container spacing={1} alignItems="flex-end" className={classes.containerInput}>
            <Grid item xs={1}>
              <MessageRoundedIcon color="primary" />
            </Grid>
            <Grid item xs={10}>
              <TextField multiline rowsMax={4} color="primary" className={classes.margin} name="mensaje" label="Mensaje" variant="outlined" value={form.mensaje} onChange={onChange} fullWidth />
            </Grid>
          </Grid>
        </div>
      </Grow>
      <center>
        <Grow in={true} style={{ transformOrigin: '0 0 0' }} {...(true ? { timeout: 800 } : {})}>
          <Button variant="contained" color="primary" className={classes.margin} onClick={handleSubmit}>
            ENVIAR
          </Button>
        </Grow>
      </center>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },

  containerForm: {
    diplay: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  containerInput: {
    diplay: 'flex',
    alignItems: 'center',
  },
}));
