import React from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';

export const Map = () => {
  const map = {
    lng: -78.11167966136317,
    lat: 0.3589614610537069,
    zoom: 16,
  };
  const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiYW5kcmVzc2FsZ2Fkb2MxIiwiYSI6ImNrMXRyN2c2bDAxZHUzb3FmcmRpdWIwdDIifQ.uT9V4dExJjvVNRWPQaZT_Q',
  });

  return (
    <Map
      // eslint-disable-next-line
      style="mapbox://styles/mapbox/streets-v9"
      containerStyle={{
        height: '50vh',
        width: '100%',
      }}
      center={[map.lng, map.lat]}
      zoom={[map.zoom]}
    >
      <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
        <Feature coordinates={[map.lng, map.lat]} />
      </Layer>
    </Map>
  );
};
