import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, Button, Hidden, makeStyles } from '@material-ui/core';

export const DialogConvocatoria = ({ open, handleClose }) => {
  const classes = useStyles();
  const url = 'https://fcpcutn.com.ec/files';
  const nameFile = 'convocatoria-asamblea-ordinaria.pdf';

  return (
    <div>
      <Dialog fullWidth={true} maxWidth="md" onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        
        <DialogContent dividers>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Hidden only={['xs']}>
              <iframe title="pdf" src={`${url}/${nameFile}`} width="100%" height="500px"></iframe>
            </Hidden>
            <Hidden only={['md', 'xl', 'lg', 'sm']}>
              <div>
                <p>
                  Abrir PDF &nbsp;
                  <a rel="noreferrer" href={`${url}/${nameFile}`} target="_blank">
                    aquí
                  </a>
                  .
                </p>
              </div>
              {/* <div style={{ height: '100vh' }}></div> */}
            </Hidden>
          </div>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  title: { margin: theme.spacing(2), fontWeight: 'bold' },
  margin: {
    margin: theme.spacing(1),
  },
  marginx4: {
    margin: theme.spacing(4),
  },
  marginTopBottomx4: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  displayCenterColumn: {
    diplay: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  containerForm: {
    diplay: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
  },
  containerInput: {
    diplay: 'flex',
    alignItems: 'center',
  },
  value: {
    marginLeft: theme.spacing(4.5),
  },
  typographyWithIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  mrIcon: {
    marginRight: theme.spacing(1.5),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: 'rgba(255, 255, 255, 0.7)',
  },
}));
